<template>
  <div class="display-flex">
    <div class="calendar-blur left" v-if="!arrowNaviMode"></div>
    <div
      class="calendar-navi__wrapper calendar-navi__left"
      :class="{ disabled: disabledLeftArrow }"
      @click="toPreviousDate"
      v-if="arrowNaviMode"
    >
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.91016 1.41L3.33016 6L7.91016 10.59L6.50016 12L0.500156 6L6.50016 -6.16331e-08L7.91016 1.41Z"
          fill="none"
        />
      </svg>
    </div>
    <div class="calendar-container" ref="calendarContent">
      
      <div
        class="calendar-box"
        :class="{ selected: o.selected }"
        v-for="(o, index) in calendar"
        :key="index"
        @click="selectMatchByDate(o)"
      >
        <span class="calendar-box__week">{{ $t(o.day) }}</span>
        <span class="calendar-box__monnth">{{ o.date }}{{ addCNCalendarDay }} {{ $t(o.month) }}</span>
      </div>
      
    </div>
    <div
      class="calendar-navi__wrapper calendar-navi__right"
      :class="{ disabled: disabledRightArrow }"
      @click="toNextDate"
      v-if="arrowNaviMode"
    >
      <svg
        width="8"
        height="13"
        viewBox="0 0 8 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.0898437 10.8849L4.66984 6.29492L0.0898433 1.70492L1.49984 0.294922L7.49984 6.29492L1.49984 12.2949L0.0898437 10.8849Z"
          fill="none"
        />
      </svg>
    </div>
    <div class="calendar-blur right" v-if="!arrowNaviMode"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/js/config.js";
const TODAY = "TODAY";
let before_start_date = false;
let after_end_date = false;
export default {
  props: {
    params: {
      type: String,
    },
    show: {
      type: Number,
      default: 7,
    },
    confirmStartDate: {
      type: [String, Object],
    },
    confirmEndDate: {
      type: [String, Object]
    },
    arrowNaviMode: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    params: {
      handler(newVal, oldVal) {
        this.calendar.forEach((dateObj, i) => {
          if (dateObj.fullDate === newVal) {
            this.selectMatchByDate(dateObj, false);
          }
        });
      }
    },
    confirmStartDate: {
      handler(newVal, oldVal) {
        this.init();
      }
    }
  },
  computed: {
      ...mapGetters([
          "currentLocale",
      ]),
      addCNCalendarDay() {
          if (this.cnLocaleList.includes(this.currentLocale)) {
              return "日";
          }
          return;
      },
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      dateList: [],
      calendar: [],
      highlightedDateIndex: 0,
      leftEdgeDateIndex: 0,
      rightEdgeDateIndex: 0,
      cardMarginRight: 0,
      cardMarginLeft: 0,
      cardWidth: 0,
      disabledLeftArrow: false,
      disabledRightArrow: false,
      cnLocaleList: config.cnLocaleList,
    };
  },
  methods: {
    init() {
      this.getCalendar();
    },
    /**
     * @param: emit - true if user clicked on particular calendar card, 
     *         false if triggered by WC Stage selector change
     */
    selectMatchByDate(o, emit = true) {
      this.calendar.forEach((card) => (card.selected = false));
      o.selected = true;
      this.highlightedDateIndex = o.index;
      if (emit) {
        this.$emit("weeklyDateChange", o);
      }
      this.initCalendar();
    },
    toPreviousDate() {
      if (this.leftEdgeDateIndex > 0) {
        --this.leftEdgeDateIndex;
        --this.rightEdgeDateIndex;
        this.$refs.calendarContent.scrollTo(
          document.getElementsByClassName("calendar-box")[
            this.leftEdgeDateIndex
          ].offsetLeft - this.cardMarginLeft,
          0
        );
        this.disableLeftArrow();
      }
    },
    toNextDate() {
      if (this.rightEdgeDateIndex < this.dateListLen - 1) {
        ++this.leftEdgeDateIndex;
        ++this.rightEdgeDateIndex;
        this.$refs.calendarContent.scrollTo(
          document.getElementsByClassName("calendar-box")[
            this.leftEdgeDateIndex
          ].offsetLeft - this.cardMarginLeft,
          0
        );
        this.disableRightArrow();
      }
    },
    disableLeftArrow() {
      if (this.leftEdgeDateIndex === 0) {
        this.disabledLeftArrow = true;
      }
      this.disabledRightArrow =
        this.rightEdgeDateIndex < this.dateListLen ? false : true;
    },
    disableRightArrow() {
      if (this.rightEdgeDateIndex === this.dateListLen - 1) {
        this.disabledRightArrow = true;
      }
      this.disabledLeftArrow = this.leftEdgeDateIndex > 0 ? false : true;
    },
    getCalendar() {
      let selectedFullDate = new Date(); //2022,10,30
      let selectedFullDateObj = new Date(selectedFullDate.getFullYear(), selectedFullDate.getMonth(), selectedFullDate.getDate());
      let startFullDateObj = new Date(this.confirmStartDate);
      let endFullDateObj = new Date(this.confirmEndDate);
      let isSelectedDateAdded = false;
      let newGeneratedDate;
      
      newGeneratedDate = new Date(this.confirmStartDate);
      while (newGeneratedDate <= new Date(this.confirmEndDate)) {
        let newDate = newGeneratedDate.getFullYear() + "/" + (newGeneratedDate.getMonth() + 1) + "/" + newGeneratedDate.getDate();
        this.dateList.push(newDate);
        newGeneratedDate.setDate(newGeneratedDate.getDate() + 1);
      }
      this.dateListLen = this.dateList.length;
      
      if (selectedFullDateObj < startFullDateObj) {
        before_start_date = true;
      } else if (selectedFullDateObj > endFullDateObj) {
        after_end_date = true;
      }

      this.calendar = this.dateList.map((date, i) => {
        let newFullDateObj = new Date(date);
          
        let humanDateMonth = newFullDateObj.getMonth() + 1;
        let humanDateDate = newFullDateObj.getDate();

        if (humanDateMonth < 10) {
          humanDateMonth = `0${humanDateMonth}`;
        }
        if (humanDateDate < 10) {
          humanDateDate = `0${humanDateDate}`;
        }

        let dateObj = {
          day: this.$tools.getDay(newFullDateObj.getDay()),
          month: this.$tools.getMonth(newFullDateObj.getMonth()),
          date: newFullDateObj.getDate(),
          fullDate: `${newFullDateObj.getFullYear()}-${humanDateMonth}-${humanDateDate}`,
          selected: false,
          index: i,
        };

        if (newFullDateObj.valueOf() === selectedFullDateObj.valueOf()) {
          dateObj.day = TODAY;
          dateObj.selected = true;
          this.highlightedDateIndex = i;
          isSelectedDateAdded = true;
        } else if (before_start_date && i === 0) {
          // world cup logic
          dateObj.selected = true;
          this.highlightedDateIndex = i;
          isSelectedDateAdded = true;
        } else if (after_end_date && i === this.dateListLen - 1) {
          // world cup logic
          dateObj.selected = true;
          this.highlightedDateIndex = i;
          isSelectedDateAdded = true;
        } else if (
          selectedFullDateObj < newFullDateObj &&
          !isSelectedDateAdded
        ) {
          // if no match but within the date list range
          dateObj.selected = true;
          this.highlightedDateIndex = i;
          isSelectedDateAdded = true;
        }

        return dateObj;
      });

      this.initCalendar();
    },
    initCalendar() {
      this.$nextTick(() => {
        let naviArrows = document.getElementsByClassName(
          "calendar-navi__wrapper"
        );
        let selectedCard = document.getElementsByClassName(
          "calendar-box selected"
        )[0];
        this.cardMarginRight = parseFloat(
          window
            .getComputedStyle(selectedCard)
            .getPropertyValue("margin-right")
            .split("px")[0]
        );
        this.cardMarginLeft = parseFloat(
          window
            .getComputedStyle(selectedCard)
            .getPropertyValue("margin-left")
            .split("px")[0]
        );
        this.cardWidth =
          this.cardMarginRight +
          this.cardMarginLeft +
          selectedCard.getBoundingClientRect().width;
        let scrollToXPosition;
        let cardAmountToShow = parseInt(this.show);

        if (this.dateListLen <= this.show) {
          for (const ele of naviArrows) {
            ele.style.display = "none";
          }
        } else {
          for (const ele of naviArrows) {
            ele.style.display = "flex";
          }
        }

        if (cardAmountToShow % 2 === 0) {
          // EVEN
          let leftRightCardAmount = cardAmountToShow / 2;
          if (this.highlightedDateIndex === this.dateListLen - 1) {
            // at last
            this.leftEdgeDateIndex =
              this.highlightedDateIndex - (cardAmountToShow - 1);
            this.rightEdgeDateIndex = this.highlightedDateIndex;
          } else if (this.highlightedDateIndex === 0) {
            // at start
            this.leftEdgeDateIndex = 0;
            this.rightEdgeDateIndex =
              this.highlightedDateIndex + (cardAmountToShow - 1);
          } else {
            // middle
            if (
              this.highlightedDateIndex + leftRightCardAmount >=
              this.dateListLen
            ) {
              // in last batch, selected date not at middle
              this.leftEdgeDateIndex = this.dateListLen - cardAmountToShow;
              this.rightEdgeDateIndex = this.dateListLen - 1;
            } else if (this.highlightedDateIndex - leftRightCardAmount < 0) {
              // in first batch, selected date not at middle
              this.leftEdgeDateIndex = 0;
              this.rightEdgeDateIndex =
                cardAmountToShow - this.highlightedDateIndex;
            } else {
              this.leftEdgeDateIndex =
                this.highlightedDateIndex - (leftRightCardAmount - 1);
              this.rightEdgeDateIndex =
                this.highlightedDateIndex + leftRightCardAmount;
            }
          }

          scrollToXPosition =
            selectedCard.offsetLeft -
            this.cardMarginLeft -
            (cardAmountToShow / 2 - 1) * this.cardWidth;
        } else {
          // ODD
          let leftRightCardAmount = Math.floor(cardAmountToShow / 2);
          if (this.highlightedDateIndex === this.dateListLen - 1) {
            // at last
            this.leftEdgeDateIndex =
              this.highlightedDateIndex - (cardAmountToShow - 1);
            this.rightEdgeDateIndex = this.highlightedDateIndex;
          } else if (this.highlightedDateIndex === 0) {
            // at start
            this.leftEdgeDateIndex = 0;
            this.rightEdgeDateIndex =
              this.highlightedDateIndex + (cardAmountToShow - 1);
          } else {
            // middle
            if (
              this.highlightedDateIndex + leftRightCardAmount >=
              this.dateListLen
            ) {
              // in last batch, selected date not at middle
              this.leftEdgeDateIndex = this.dateListLen - cardAmountToShow;
              this.rightEdgeDateIndex = this.dateListLen - 1;
            } else if (this.highlightedDateIndex - leftRightCardAmount < 0) {
              // in first batch, selected date not at middle
              this.leftEdgeDateIndex = 0;
              this.rightEdgeDateIndex =
                cardAmountToShow - this.highlightedDateIndex;
            } else {
              this.leftEdgeDateIndex =
                this.highlightedDateIndex - leftRightCardAmount;
              this.rightEdgeDateIndex =
                this.highlightedDateIndex + leftRightCardAmount;
            }
          }

          scrollToXPosition =
            selectedCard.offsetLeft -
            this.cardMarginLeft -
            Math.floor(cardAmountToShow / 2) * this.cardWidth;
        }

        this.disableLeftArrow();
        this.disableRightArrow();

        if (this.arrowNaviMode) {
          this.$refs.calendarContent.style.width = this.cardWidth * this.show + "px";
          this.$refs.calendarContent.style.overflow = "hidden";    
        } else {
          this.$refs.calendarContent.style.width = "92vw";
        }
        this.$refs.calendarContent.scrollTo(scrollToXPosition, 0);
      });
    },
  },
};
</script>

<style scoped>
.calendar-container {
  display: flex;
  position: relative;
  border-bottom: 0;/*.1rem solid var(--color-theme)*/
}
.calendar-navi__wrapper {
  display: flex;
  align-items: center;
}
.calendar-navi__wrapper.disabled svg path {
  fill: var(--color-theme);
}
.calendar-navi__left {
  padding: 0.5rem 0.875rem 0.5rem 0.5rem;
  cursor: pointer;
}
.calendar-navi__right {
  padding: 0.5rem 0.5rem 0.5rem 0.875rem;
  cursor: pointer;
}
.calendar-navi__wrapper svg path {
  fill: var(--bg-selected);
}
.calendar-box {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 0.66rem;
  padding: 0.3rem 0.125rem 0.55rem 0.125rem;
  margin: 0 0.34rem 0.3rem;
  min-width: 2.65rem;
  cursor: pointer;
  text-align: center;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  z-index: 1;
}
.calendar-box.selected {
  color: black !important;
  background-color: var(--bg-selected) !important;
}
.calendar-box:hover {
  color: var(--color-hover-20);
  background-color: var(--color-hover);
}

.calendar-box.selected::after {
  bottom: -9.5px;
  left: 50%;
  border-style: solid;
  border-image: initial;
  content: " ";
  height: 0px;
  width: 0px;
  position: absolute;
  pointer-events: none;
  border-color: var(--bg-selected) rgba(255, 138, 0, 0) rgba(255, 138, 0, 0);
  border-width: 5px 7px;
  margin-left: -7px;
}

.calendar-box__week {
  font-weight: 700;
}
.calendar-box__monnth {
  font-weight: 400;
}

@media (max-width: 768px) {
  .calendar-blur {
    width: 2rem;
    height: 98%;
    top: 0;
    position: absolute;
    z-index: 2;
  }
  .calendar-blur.left {
    background: linear-gradient(90deg, var(--bg-theme), transparent);
    left: 0;
  }
  .calendar-blur.right {
    background: linear-gradient(90deg, transparent, var(--bg-theme));
    right: 0;
  }
  .calendar-wrapper {
    position: relative;
    width: 15rem;
    margin-right: 1.5rem;
    flex-grow: 1;
  }
  .calendar-container {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .calendar-box:hover {
    color: white;
    background-color: transparent;
  }
}
</style>
