<template>
    <div class="live-stream-countdown__container">
        <div class="live-stream-countdown__wrapper">
            <div class="live-stream-countdown__overlay">
            <!-- getGroupStage -->
                <div class="live-stream-countdown__group-wrapper"><span v-if="groupName !== null">{{groupName}} · </span>{{groupStage}}</div>
                <div class="display-flex" v-if="!hideCountDown">
                    <div class="live-stream-countdown__time-wrapper">
                        <div class="time-value">{{daysValue}}</div>
                        <div class="time-caption">{{$t("DAYS")}}</div>
                    </div>
                    <div class="live-stream-countdown__time-wrapper">
                        <div class="time-value">{{hoursValue}}</div>
                        <div class="time-caption">{{$t("HOURS")}}</div>
                    </div>
                    <div class="live-stream-countdown__time-wrapper">
                        <div class="time-value">{{minutesValue}}</div>
                        <div class="time-caption">{{$t("MINUTES")}}</div>
                    </div>
                    <div class="live-stream-countdown__time-wrapper">
                        <div class="time-value">{{secondsValue}}</div>
                        <div class="time-caption">{{$t("SECONDS")}}</div>
                    </div>
                </div>
            
                <div class="live-stream-countdown__starting-soon-message" v-else>{{ $t("LIVESTREAM_IS_STARTING_SOON") }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        matchDate: {
            type: String,
        },
        matchTime: {
            type: String,
        },
        groupName: {
            type: String,
        },
        groupStage: {
            type: String,//Object
        }
    },
    data() {
        return {
            daysValue: "00",
            hoursValue: "00",
            minutesValue: "00",
            secondsValue: "00",
            countDownInterval: "",
            hideCountDown: false,
        };
    },
    computed: {
        getFullEndTime() {
            let newDate = this.matchDate.replace(/-/g, "/");
            return newDate + " " + this.matchTime;
        },
        // getGroupStage() {
        //     if (this.groupStage.colored) {
        //         return " · " + this.groupStage.subLeagueName;
        //     } else {
        //         return;
        //     }
        // },
    },
    beforeUnmount() {
        clearInterval(this.countDownInterval);
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.initCountDown();
        },
        initCountDown() {
            this.updateClock();
            this.countDownInterval = setInterval(this.updateClock, 1000);
        },
        updateClock() {
            let remainingTime = this.getRemainingTime();
            if (remainingTime <= 0) {
                this.hideCountDown = true;
                this.$emit("countDownEnd");
                clearInterval(this.countDownInterval);
            }
        },
        getRemainingTime() {
            let timeLeft = (Date.parse(this.getFullEndTime) - Date.parse(new Date())) / 1000;
            let tempDay = Math.floor((timeLeft / 3600) / 24);
            this.daysValue = (timeLeft <= 0) ? "00" : (tempDay < 10) ? "0" + tempDay : tempDay;
            this.hoursValue = (timeLeft <= 0) ? "00" : ("0" + Math.floor((timeLeft / 3600) % 24)).slice(-2);
            this.minutesValue = (timeLeft <= 0) ? "00" : ("0" + Math.floor((timeLeft / 60) % 60)).slice(-2);
            this.secondsValue = (timeLeft <= 0) ? "00" : ("0" + Math.floor((timeLeft) % 60)).slice(-2);

            return timeLeft;
        },
    },
}
</script>

<style>
.live-stream-countdown__container {
    background: #212B30;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding: 0.625rem 0.5rem;
    position: relative;
}
#AFC .live-stream-countdown__wrapper {
    background: url(../../static/images/footballEvents/afc_live_countdown_bg.jpg) center no-repeat;
    background-size: cover;
    width: 100%;/*41.375rem*/
    height: 25.5rem;
    border-radius: 0.188rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#AFCON .live-stream-countdown__wrapper {
    background: url(../../static/images/footballEvents/afcon_live_countdown_bg.jpg) center no-repeat;
    background-size: cover;
    width: 100%;/*41.375rem*/
    height: 25.5rem;
    border-radius: 0.188rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#UEFA .live-stream-countdown__wrapper {
    background: url(../../static/images/footballEvents/uefa_live_countdown_bg.jpg) center top no-repeat;
    background-size: cover;
}
#COPA .live-stream-countdown__wrapper {
    background: url(../../static/images/footballEvents/copa_live_countdown_bg.jpg) center bottom no-repeat;
    background-size: cover;
}
.live-stream-countdown__wrapper {
    background: url(../../static/images/world_cup_live_countdown_bg.jpg) center no-repeat;
    background-size: cover;
    width: 100%;/*41.375rem*/
    height: 25.5rem;
    border-radius: 0.188rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.live-stream-countdown__overlay {
    background: rgba(37, 35, 35, 0.60);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.live-stream-countdown__group-wrapper {
    margin-bottom: 1.25rem;
    font-size: 1.375rem;
    font-weight: 700;
    color: #FFFFFF;
}
.live-stream-countdown__time-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #080F1A;
    border-radius: 1.375rem;
    width: 4.375rem;
    height: 4.875rem;
    padding: 0.5625rem;
    margin-right: 0.913rem;
}
.live-stream-countdown__time-wrapper:last-of-type {
    margin-right: 0;
}
.live-stream-countdown__time-wrapper .time-value {
    font-size: 2.125rem;
    font-weight: 700;
    color: #FFFFFF;
}
.live-stream-countdown__time-wrapper .time-caption {
    color: var(--bg-selected);
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 400;
}
.live-stream-countdown__starting-soon-message {
    font-size: 2.125rem;
    font-weight: 700;
}
@media (max-width: 768px) {
    .live-stream-countdown__wrapper {
        height: 11.813rem;
    }
    .live-stream-countdown__group-wrapper {
        font-size: 1.125rem;
    }
    .live-stream-countdown__time-wrapper {
        width: 3.125rem;
        height: 3.125rem;
    }
    .live-stream-countdown__time-wrapper .time-value {
        font-size: 1.375rem;
    }
    .live-stream-countdown__time-wrapper .time-caption {
        font-size: 0.563rem;
    }
    .live-stream-countdown__starting-soon-message {
        font-size: 1.4rem;;
    }
}
</style>